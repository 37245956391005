import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import 'animate.css';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { theme } from 'common/theme/saasAppDark';
import Seo from 'components/seo';
import AppDownload from 'containers/SaasAppDark/AppDownload';
import Banner from 'containers/SaasAppDark/Banner';
import CallToAction from 'containers/SaasAppDark/CallToAction';
import Footer from 'containers/SaasAppDark/Footer';
import Monitoring from 'containers/SaasAppDark/Monitoring';
import Navbar from 'containers/SaasAppDark/Navbar';
import { ContentWrapper, GlobalStyle } from 'containers/SaasAppDark/saasAppDark.style';
import Services from 'containers/SaasAppDark/Services';
import StatsCounter from 'containers/SaasAppDark/StatsCounter';
import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';

const SaasAppCreative = () => {
  return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Seo title="Mambo AI ✨| Your marketing compliance partner" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Banner />
            {/*<Clients />*/}
            <Services />
            <Monitoring />
            <StatsCounter />
            {/*<VideoIntro />
            <Testimonials />*/}
            <CallToAction />
            {/*<NewsFeed />*/}
            <AppDownload />
            {/*<Footer />*/}
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
  );
};
export default SaasAppCreative;
