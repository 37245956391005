import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import React from 'react';
import Section, { BGImage, SectionHeading } from './cta.style';

const VideoIntro = () => {
  return (
    <Section id="security">
      <BGImage>
        <SectionHeading>
          <Text
            as="span"
            className="subtitle"
            content="🔒 Safe, Secure, Compliant"
          />
          <Heading content="We take security and compliance seriously" />
          <Text content="We prioritize security and compliance. Mambo is built by financial services experts in the US who've developed products used by millions. We're steadfast in our commitment to data security and privacy. We use the latest best practices to safeguard your data. ✨" />
        </SectionHeading>
      </BGImage>
    </Section>
  );
};

export default VideoIntro;
