import { default as Heading, default as Text } from 'common/components/Text';
import Container from 'common/components/UI/Container';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Section, { Grid, Item, SectionHeading } from './services.style';

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppDarkJson {
        SERVICES {
          id
          title
          icon {
            publicURL
          }
        }
      }
    }
  `);

  const { saasAppDarkJson : SERVICES } = data;
  
  return (
    <Section id="about">
      <Container width="1400px">
        <SectionHeading>
          <Heading
            as="h2"
            content="Your AI-Powered Marketing Compliance Partner"
          />
          <Text content="Welcome to a smarter way to handle compliance. With Mambo AI, your marketing and legal teams get an AI assistant trained to understand the nuance of modern regulatory frameworks, including the critical changes in the SEC Marketing Rule. From compliant testimonials to disclosures and beyond, we’ve got the details covered. All this so your compliance staff can concentrate on strategic issues while your marketing team enjoys a smoother path to market." />
        </SectionHeading>
        <Grid>
          {SERVICES?.SERVICES?.map((service, index) => (
            <Item key={index}>
              <span className="icon">
              <img src={service.icon.publicURL} alt={service.title} />
            </span>
              <Heading as="h4" content={service.title} />
            </Item>
          ))}
        </Grid>
        <div className="fyi"><strong>💡 Did You Know?</strong> Our system is continually updated with the latest in compliance regulations, including the SEC Marketing Rule, ensuring you're always ahead of the curve.</div>
      </Container>
    </Section>
  );
};

export default Services;
