import dashboard from 'common/assets/image/Mambo-AI-Google-Doc.png';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import React from 'react';
import Section, { BannerContent, BannerContentWrapper, Figure } from './banner.style';
import EmailCapture from 'common/components/EmailCapture';

const Banner = () => {

  return (
    <Section id="home">
      <Container width="1300px">
        <BannerContentWrapper>
          <BannerContent>
            <h1 className="animate__animated animate__fadeInUp">
              Navigate Marketing Compliance <span>the easy way</span>
            </h1>
            <Text
              className="animate__animated animate__fadeInUp"
              content="Accelerate your go-to-market strategy while ensuring full compliance. Empower your marketing team and free up your compliance staff to focus on what really matters."
            />
            <div className="email-form">
              <EmailCapture />
            </div>
          </BannerContent>
          <Figure className="hero-banner">
            <Image src={dashboard} alt="dashboard" />
          </Figure>
        </BannerContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
