import report1 from 'common/assets/image/risk-score.svg';
import report2 from 'common/assets/image/soften-language.svg';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Fade from 'react-reveal/Fade';
import Section, { Content, FeatureList, FigureGroup, Grid } from './monitoring.style';

const Monitoring = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppDarkJson {
        MONITORING_FEATURES {
          desc
          icon {
            publicURL
          }
          id
          title
        }
      }
    }
  `);

  const { saasAppDarkJson : MONITORING_FEATURES } = data;
  
  return (
    <Section id="features">
      <Container width="1300px">
        <Grid>
          <FigureGroup>
            <Fade up>
              <Image src={report2} alt="Mambo example" />
            </Fade>
            <div className="graph-2">
              <Fade up delay={200}>
                <Image src={report1} alt="Mambo example 2" />
              </Fade>
            </div>
          </FigureGroup>
          <Content>
            <Text className="subtitle" content="AI Content Review" />
            <Heading content="How Mambo AI Reviews Your Content" />
            <Text
              className="description"
              content="Leverage AI to review your marketing content for compliance. Before sending your content for approval, let Mambo AI be your first line of review. This ensures speed, accuracy, and gives your compliance team a head-start."
            />
            <FeatureList>
              {MONITORING_FEATURES?.MONITORING_FEATURES?.map((feature) => (
                <div className="feature" key={feature.id}>
                  <figure className="icon">
                    <Image src={feature?.icon?.publicURL} alt="feature icon" />
                  </figure>
                  <div className="feature-content">
                    <Heading as="h4" content={feature?.title} />
                    <Text content={feature?.desc} />
                  </div>
                </div>
              ))}
            </FeatureList>
            <Text
                className="fyi"
                content="⚠️ Mambo AI is a compliance supplement, not a replacement. Final approvals from your compliance team are still necessary."
            />
          </Content>
        </Grid>
      </Container>
    </Section>
  );
};

export default Monitoring;
